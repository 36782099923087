
import { Elm } from './Main.elm'
import './styles/main.scss'

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {}
});

app.ports.sendToJs.subscribe((cmd) => {
  cmd = cmd.toUpperCase()
  switch (cmd) {
    case "PRINT":
      window.print();
      break;
    default:
      console.error("UNKNOWN COMMAND: " + cmd);
      break;
  }
});
